<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="show"
    max-width="500px"
    max-height="800px"
    scrollable
    persistent
  >
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-card background-color="#f2f2f2" v-else>
      <v-card-title
        class="py-0 pt-7"
        style="background-color: #7253cf; color: white"
      >
        <div class="d-flex px-1">
          <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
        </div>
        <div class="ml-1 dialogTitle-text">Update Hotel Info</div>
        <v-spacer></v-spacer>
        <v-icon
          text
          large
          color="white"
          style="
            cursor: pointer;
            position: relative;
            right: -15px;
            bottom: 40px;
          "
          @click="toggleHotelInfoModal({ show: false })"
          >mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pt-10">
        <v-form lazy-validation ref="hotelInfoForm">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="hotelName"
                label="Hotel Name"
                color="#7253CF"
                class="formFields"
                :rules="[rules.required]"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="hotel_address"
                label="Hotel Address"
                color="#7253CF"
                class="formFields"
                :rules="[rules.required]"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="rate"
                label="Rate"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="contactPersonName"
                label="Hotel Contact"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="contact_email"
                label="Email"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="phone"
                label="Phone"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
                 :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pr-2" v-if="this.adminAccess != 'game'">
              <v-autocomplete
                label="Team"
                outlined
                dense
                v-model="team"
                :items="teamNameList"
                class="formFields"
                item-text="team_name"
                item-value="id"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" v-if="this.team == null && this.adminAccess != 'game'">
              <v-combobox
                v-model="players"
                :items="playerList"
                label="Player"
                class="formFields"
                item-text="name"
                item-value="id"
                color="#7253CF"
                :disabled="formLoading"
                multiple
                outlined
                dense
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="noOfRooms"
                label="Number of Rooms"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
                type="number"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pr-2">
              <v-menu
                ref="menu"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="checkInDateFormatted"
                    prepend-inner-icon="mdi-calendar-range"
                    label="Check In Date"
                    v-bind="attrs"
                    readonly
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :rules="[rules.required]"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="checkInDate"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="checkOutDateFormatted"
                    prepend-inner-icon="mdi-calendar-range"
                    label="Checkout Date"
                    v-bind="attrs"
                    readonly
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :rules="[rules.required]"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="checkOutDate"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                row-height="4"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
                v-model="confirmation_number"
                label="Confirmation Number"
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                row-height="4"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
                v-model="note"
                label="notes"
              ></v-textarea>
            </v-col>
              <v-col cols="12">
              <v-checkbox
                v-model="early_check_in"
                label="Early Check In"
                :true-value=true
                :false-value=false
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="dialogAction-btnText text-capitalize px-7"
          height="45"
          color="#38227A"
          :loading="formLoading"
          @click="submitForm"
        >
          <span>{{ actionText }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import moment from "moment";
import {
  API_HOTEL_INFO_POST,
  API_HOTEL_INFO_PATCH,
  GAME_HOTEL_GET_API,
  API_ADMIN_TEAM_NAME_LIST,
  API_EVENT_TEAM_PLAYER_LIST_GET,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "HotelInfoModal",
  data() {
    return {
    adminAccess:localStorage.getItem("adminAccess"),
      menu1: false,
      menu2: false,
      rules: RULES,
      loading: false,
      formLoading: false,
      hotelName: "",
      contactPersonName: "",
      mobile: null,
      contact_email: "",
      phone: "",
      note: "",
      noOfRooms: null,
      checkInDate: "",
      checkOutDate: "",
      hotel_address: "",
      confirmation_number: "",
      rate: "",
      lisssst: [{ id: null, team_name: "unassigned" }],
      demoTeamList: [this.lisssst + this.teamNameList],
      teamNameList: [],
      players: [],
      playerList: [],
      team_name: "",
      team_id: "",
      team: "",
      early_check_in:false,
    };
  },
  computed: {
    ...mapGetters({
      getShow: "gameManagement/getHotelInfoShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.toggleHotelInfoModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.hotelInfoDetail.type;
    },
    actionText() {
      if (this.type === "add") {
        return "Add new Hotel";
      } else {
        return "Save";
      }
    },
    checkInDateFormatted: {
      get() {
        if (this.checkInDate) {
          return moment(this.checkInDate).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return null;
      },
    },
    checkOutDateFormatted: {
      get() {
        if (this.checkOutDate) {
          return moment(this.checkOutDate).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return null;
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleHotelInfoModal: "gameManagement/toggleHotelInfoModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getteamPlayer();
      this.getTeamList();
      if (this.type === "edit") {
        this.getHotelDetail();
      }
    },
    closeModal() {
      this.$refs.hotelInfoForm.reset();
      this.loading = false;
      this.formLoading = false;
      this.hotelName = "";
      this.contactPersonName = "";
      this.mobile = null;
      this.contact_email = "";
      this.note = "";
      this.noOfRooms = null;
      this.checkInDate = "";
      this.checkOutDate = "";
    },
    getHotelDetail() {
      const successHandler = (res) => {
        console.log(res);
        this.loading = false;
        this.hotel_address = res.data.hotel_detail.hotel_address;
        this.hotelName = res.data.hotel_detail.name;
        this.contact_email = res.data.hotel_detail.contact_email;
        this.phone = res.data.hotel_detail.phone;
        this.contactPersonName = res.data.hotel_detail.contact_person_name;
        this.confirmation_number = res.data.hotel_detail.confirmation_number;
        this.rate = res.data.hotel_detail.rate;
        this.checkInDate = res.data.hotel_detail.checking_in_date;
        this.checkOutDate = res.data.hotel_detail.checking_out_date;
        this.note = res.data.hotel_detail.note;
        this.noOfRooms = res.data.hotel_detail.number_of_rooms;
         this.early_check_in = res.data.hotel_detail.early_check_in;
        this.team = res.data.hotel_detail.team;
        if (res.data.hotel_detail.player_list != null) {
          let playerIdList = res.data.hotel_detail.player_list.map(function (
            obj
          ) {
            return obj.id;
          });
          this.players = this.playerList.filter((k) =>
            playerIdList.includes(k.id)
          );
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["hotel_id"] =
        this.$store.state.gameManagement.hotelInfoDetail.hotelID;
      Axios.request_GET(
        GAME_HOTEL_GET_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getteamPlayer() {
      const successHandler = (res) => {
        this.playerList = res.data.player_list;
        // this.setplayers(res.data.player_list);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      if (this.team_name) {
        formData["team_name"] = this.team_name;
      }
      Axios.request_GET(
        API_EVENT_TEAM_PLAYER_LIST_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    setplayers(playerList) {
      const self = this;
      if (this.players[0]) {
        let res = [];
        res = playerList.filter((el) => {
          return !self.filterList.find((element) => {
            return element.id === el.id;
          });
        });
        self.players = self.players.concat(res);
      } else {
        this.players = playerList;
        this.filterList = playerList;
      }
    },
    getTeamList() {
      const successHandler = (res) => {
        const teamnaeee = res.data.team_name_list;
        const teamlistsss = [{ id: null, team_name: "Custom" }];
        this.teamNameList = teamlistsss.concat(teamnaeee);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      Axios.request_GET(
        API_ADMIN_TEAM_NAME_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    submitForm() {
      if (this.$refs.hotelInfoForm.validate()) {
        this.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
           this.$emit("reload");
          this.toggleHotelInfoModal({ show: false });
          this.showToast({
            message: "Updated Successfully",
            color: "s",
          });
          this.formLoading = false;
        };
        const failureHandler = (res) => {
          console.log(res);
          this.showToast({
            message: res,
            color: "e",
          });
          this.formLoading = false;
        };
        let formData = {};
        formData["game"] = this.$route.query.game_id;
        if (this.hotelName) {
          formData["name"] = this.hotelName;
        }
        if (this.contactPersonName) {
          formData["contact_person_name"] = this.contactPersonName;
        }
        if (this.contact_email) {
          formData["contact_email"] = this.contact_email;
        }
        if (this.phone) {
          formData["phone"] = this.phone;
        }
        if (this.noOfRooms) {
          formData["number_of_rooms"] = this.noOfRooms;
        }
        if (this.hotel_address) {
          formData["hotel_address"] = this.hotel_address;
        }
        if (this.confirmation_number) {
          formData["confirmation_number"] = this.confirmation_number;
        }
        if (this.rate) {
          formData["rate"] = this.rate;
        }
        if (this.checkInDateFormatted) {
          formData["checking_in_date"] = this.checkInDateFormatted;
        }
        if (this.checkOutDateFormatted) {
          formData["checking_out_date"] = this.checkOutDateFormatted;
        }
        if (this.note) {
          formData["note"] = this.note;
        }
         this.playerCopy = [];
        Object.keys(this.players).forEach((key) =>
          this.playerCopy.push(this.players[key].id)
        );
        if (this.team) {
          formData["team"] = this.team;
            formData["players"] = [];
        } else {
          formData["team"] = null;
            formData["players"] = this.playerCopy;
        }
        if (this.adminAccess == "game") {
          formData["approved"] = false;
        }
        formData["early_check_in"]=this.early_check_in;
        if (this.type === "add") {
          Axios.request_POST(
            API_HOTEL_INFO_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          formData["id"] =
            this.$store.state.gameManagement.hotelInfoDetail.hotelID;
          Axios.request_PATCH(
            API_HOTEL_INFO_PATCH,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 20px;
}
img.logo {
  margin-left: 20px;
  max-height: 80px;
}
.file-input-col {
  margin-left: 20px;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
